import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IUseFormEmpresas {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  idContract: string;
}

export const UseFormContractsFiles = ({
  setOpenModal,
  idContract,
}: IUseFormEmpresas) => {
  const { handleGetAlert } = useLayoutMainContext();
  const { setAttTable } = useConfigPageContext();
  const { uploadFiles } = useContextContrato();

  const [loading, setLoading] = useState(false);

  const handleCreate = async () => {
    setLoading(true);

    const formData = new FormData();

    uploadFiles.forEach((file) => {
      const blob = new Blob([file.pdf], { type: file.type });
      formData.append("files", blob, file.name);
    });

    return api
      .post(`/contracts-files/${idContract}`, formData)
      .then((res) => {
        setAttTable((item) => !item);
        handleGetAlert({ message: res.data.message });
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleSubmit: handleCreate, loading };
};
