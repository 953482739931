import { IInputCreateLeads } from "../LeadsTypes";

export const valuesDefaultInputLeads: IInputCreateLeads = {
  name: "",
  sourceCollection: "INDICATION",
  status: "NEW",
  interestLevel: "MEDIUM",
  email: "",
  whats: "",
  birthDate: "",
  telefone1: "",
  observation: "",
  cpf: "",
  pendingActions: "",
  reasonAbandonment: "",
};
