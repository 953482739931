import DeleteIcon from "@mui/icons-material/Delete";
import { alpha, Box, CardMedia, Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { handleFormatSize } from "src/shared/Utils/getFileSize";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

interface ICardPreviewFile {
  onClick: () => void;
  title: string;
  url: string;
  type:
    | "application/pdf"
    | "image/WEBP"
    | "image/GIF"
    | "image/JPG"
    | "image/jpeg"
    | "image/png";
  size: number;
}
interface IIconPreview {
  url: string;

  type: string;
}

export const IconImagePreview = ({ type, url }: IIconPreview) => {
  return (
    <Tooltip title="Clique para abrir">
      {type.includes("image") ? (
        <CardMedia
          component="img"
          sx={{
            height: "45px",
            cursor: "pointer",
            width: "45px",
            borderRadius: "8px",
            mr: "16px",
          }}
          image={url}
        />
      ) : (
        <Box
          sx={{
            width: "45px",
            height: "45px",

            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
            alignItems: "center",

            borderRadius: "8px",
            mr: "16px",
            background: (theme) => alpha(theme.palette.primary.main, 0.8),
          }}
        >
          {type.includes("pdf") ? (
            <PictureAsPdfIcon fontSize="medium" sx={{ color: "white" }} />
          ) : (
            <DriveFolderUploadIcon fontSize="medium" />
          )}
        </Box>
      )}
    </Tooltip>
  );
};

export const CardPreviewFile = ({
  onClick,
  title,
  size,
  type,
  url,
}: ICardPreviewFile) => {
  return (
    <CardCore
      elevation={2}
      sxStyle={{
        padding: "12px",
        margin: "8px 0",
        height: "4rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => window.open(url, "_blank")}
      >
        <IconImagePreview type={type} url={url} />
        <Box>
          <Typography sx={{ fontSize: ".8rem" }}>{title}</Typography>
          <Typography sx={{ fontSize: ".8rem" }}>
            {handleFormatSize(size)}
          </Typography>
        </Box>
      </Box>

      <IconButton
        onClick={onClick}
        sx={{
          boxShadow: "rgba(0, 0, 0, 0.144) 0px 3px 8px;",
          marginRight: "10px",
        }}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </CardCore>
  );
};
