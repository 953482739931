import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IHandleGet {
  contractId?: string | undefined;
}

export const useHttpTableContractsFiles = ({ contractId }: IHandleGet) => {
  const { setQtdRegisters, setLoadingTable } = useConfigPageContext();
  const { setListContractsFiles } = useContextContrato();
  const { handleGetAlert } = useLayoutMainContext();

  const handleGet = async () => {
    setLoadingTable(true);

    api
      .get(`/contracts-files/${contractId}`)
      .then((res) => {
        setListContractsFiles(res.data.data);
        setQtdRegisters(res.data.meta.total);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingTable(false));
  };

  return { handleGet };
};
