import { Box, Typography } from "@mui/material";
import { toAbsoluteUrl } from "src/shared/setup/toAbsoluteUrl";

interface ILogo {
  width?: string;
  height?: string;
}

export const Logo = ({ height = "80px", width = "80px" }: ILogo) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <img
        alt="logo da empresa "
        src={toAbsoluteUrl("/media/logos/CORRECTORS - curta.svg")}
        style={{ height, width }}
      />
      <Typography
        sx={{
          marginLeft: "-22px",
          marginBottom: "-16px",
          fontSize: "14px",
          letterSpacing: 1,
        }}
      >
        Correctors
      </Typography>
    </Box>
  );
};
