import { ChangeEvent } from "react";
import { formatString } from "@format-string/core";
import { Grid, MenuItem } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ICreateContrato } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { AppTextField } from "src/Pages/components";
import { FormatRG } from "src/shared/Utils/FormatRG/FormatRG";
import { cpfOurCnpj } from "src/shared/Utils/MaskCpfCnpj";

export const FormTitular = () => {
  const { valuesInputsContrato, setValuesInputsContrato } =
    useContextContrato();

  const handleChange =
    (prop: keyof ICreateContrato, option?: "cpf" | "rg" | "cpfCnpj") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "cpfCnpj") {
        if (
          valuesInputsContrato.contractType === "PF" &&
          event.target.value.length <= 14
        ) {
          setValuesInputsContrato((eventPrev) => ({
            ...eventPrev,
            [prop]: cpfOurCnpj({ text: event.target.value, type: "cpf" }),
          }));
        }
        if (
          valuesInputsContrato.contractType === "PJ" &&
          event.target.value.length <= 20
        ) {
          setValuesInputsContrato((eventPrev) => ({
            ...eventPrev,
            [prop]: cpfOurCnpj({ text: event.target.value, type: "cnpj" }),
          }));
        }
      } else if (option === "cpf") {
        setValuesInputsContrato((eventPrev) => ({
          ...eventPrev,
          [prop]: formatString({ value: event.target.value, type: "cpf" }),
        }));
      } else if (option === "rg") {
        setValuesInputsContrato((eventPrev) => ({
          ...eventPrev,
          [prop]: FormatRG(event.target.value),
        }));
      } else {
        setValuesInputsContrato((eventPrev) => ({
          ...eventPrev,
          [prop]: event.target.value,
        }));
      }
    };

  return (
    <Grid container spacing={2}>
      {valuesInputsContrato.contractType === "PF" && (
        <>
          <Grid item xs={12} md={5}>
            <AppTextField
              label="Titular *"
              placeholder="Nome completo"
              fullWidth
              value={valuesInputsContrato.holder}
              onChange={handleChange("holder")}
            />
          </Grid>
          <Grid item xs={6} md={3.5}>
            <AppTextField
              label="CPF *"
              placeholder="760.400.145.58"
              fullWidth
              value={valuesInputsContrato.cpf}
              onChange={handleChange("cpf", "cpfCnpj")}
            />
          </Grid>
          <Grid item xs={6} md={3.5}>
            <AppTextField
              label="RG"
              fullWidth
              value={valuesInputsContrato.rg}
              onChange={handleChange("rg", "rg")}
            />
          </Grid>
          <Grid item xs={6} md={2.5}>
            <AppTextField
              label="Data nascimento"
              fullWidth
              type="date"
              value={valuesInputsContrato.birthDate}
              onChange={handleChange("birthDate")}
            />
          </Grid>
          <Grid item xs={6} md={2.5}>
            <AppTextField
              select
              id="Estado Civil"
              label="Estado civil"
              fullWidth
              value={valuesInputsContrato?.maritalStatus}
              onChange={handleChange("maritalStatus")}
            >
              <MenuItem value="NONE">Não quero informar</MenuItem>
              <MenuItem value="MARRIED">Solteiro</MenuItem>
              <MenuItem value="married">Casado</MenuItem>
              <MenuItem value="SEPARATE">Separado</MenuItem>
              <MenuItem value="DIVORCED">Divorciado</MenuItem>
              <MenuItem value="WIDOWER">Viúvo</MenuItem>
            </AppTextField>
          </Grid>
          <Grid item xs={6} md={2.5}>
            <AppTextField
              select
              id="Gênero"
              label="Gênero"
              fullWidth
              value={valuesInputsContrato?.gender}
              onChange={handleChange("gender")}
            >
              <MenuItem value="NONE">Não quero informar</MenuItem>
              <MenuItem value="MALE">Masculino</MenuItem>
              <MenuItem value="FEMALE">Feminino</MenuItem>
              <MenuItem value="OTHER">Outros</MenuItem>
            </AppTextField>
          </Grid>
          <Grid item xs={12} md={4.5}>
            <AppTextField
              label="Nome da Mãe"
              fullWidth
              placeholder="Nome completo da mãe"
              value={valuesInputsContrato.motherName}
              onChange={handleChange("motherName")}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <AppTextField
              label="Nome do responsável"
              fullWidth
              value={valuesInputsContrato.guardianName}
              onChange={handleChange("guardianName")}
            />
          </Grid>
          <Grid item xs={6} md={3.5}>
            <AppTextField
              label="CPF Responsável"
              fullWidth
              value={valuesInputsContrato.guardianCpf}
              onChange={handleChange("guardianCpf", "cpf")}
            />
          </Grid>
          <Grid item xs={6} md={3.5}>
            <AppTextField
              label="Data nasc. Resp"
              fullWidth
              type="date"
              value={valuesInputsContrato.guardianBirthDate}
              onChange={handleChange("guardianBirthDate")}
            />
          </Grid>
        </>
      )}

      {valuesInputsContrato.contractType === "PJ" && (
        <>
          <Grid item xs={6} md={3}>
            <AppTextField
              label="CNPJ *"
              fullWidth
              value={valuesInputsContrato.cnpj}
              onChange={handleChange("cnpj", "cpfCnpj")}
            />
          </Grid>
          <Grid item xs={6} md={5}>
            <AppTextField
              label="Nome fantasia *"
              fullWidth
              value={valuesInputsContrato.companyName}
              onChange={handleChange("companyName")}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
