/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createRef, useEffect, ChangeEvent } from "react";
import { Grid } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { IUpdateInstallment } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { AppTextField } from "src/Pages/components";
import { FormatMoney, handleFormatPercent } from "src/shared/Utils/FormatMoney";

export const FormEditarParcela = () => {
  const { valuesInputUpdateInstallment, setValuesInputUpdateInstallment } =
    useContextContrato();

  const refOrigem = createRef<HTMLInputElement>();
  const handleFocusedID = () => refOrigem.current?.focus();

  useEffect(() => {
    handleFocusedID();
  }, []);

  const handleChange =
    (
      prop: keyof IUpdateInstallment,
      option?: "limitNumber" | "money" | "percent"
    ) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "money") {
        setValuesInputUpdateInstallment({
          ...valuesInputUpdateInstallment!,
          [prop]: FormatMoney(event.target.value),
        });
      } else if (option === "limitNumber") {
        if (event.target.value.length <= 5) {
          setValuesInputUpdateInstallment({
            ...valuesInputUpdateInstallment!,
            [prop]: event.target.value,
          });
        }
      } else if (option === "percent") {
        if (event.target.value.length <= 7) {
          setValuesInputUpdateInstallment({
            ...valuesInputUpdateInstallment!,
            [prop]: handleFormatPercent(event.target.value),
          });
        }
      } else {
        setValuesInputUpdateInstallment({
          ...valuesInputUpdateInstallment!,
          [prop]: event.target.value,
        });
      }
    };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <AppTextField
          fullWidth
          inputRef={refOrigem}
          label="Mensalidade *"
          value={valuesInputUpdateInstallment?.monthlyPayment}
          onChange={handleChange("monthlyPayment", "money")}
        />
      </Grid>

      <Grid item xs={3}>
        <AppTextField
          fullWidth
          label="Parcela *"
          type="number"
          value={valuesInputUpdateInstallment?.installmentNumber}
          onChange={handleChange("installmentNumber")}
        />
      </Grid>
      <Grid item xs={3}>
        <AppTextField
          fullWidth
          label="Vidas *"
          type="number"
          value={valuesInputUpdateInstallment?.lives}
          onChange={handleChange("lives")}
        />
      </Grid>

      <Grid item xs={6}>
        <AppTextField
          fullWidth
          label="Vencimento *"
          type="date"
          value={valuesInputUpdateInstallment?.dueDate}
          onChange={handleChange("dueDate")}
        />
      </Grid>

      <Grid item xs={6}>
        <AppTextField
          fullWidth
          label="Comissão"
          value={valuesInputUpdateInstallment?.commission}
          onChange={handleChange("commission", "percent")}
        />
      </Grid>
    </Grid>
  );
};
