import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IHandleGet {
  currentPage?: number | undefined;
  itensPerPage?: number | undefined;
  nameSearch?: string | undefined;
}

export const useHttpTableCommissions = () => {
  const { setQtdRegisters, setLoadingTable } = useConfigPageContext();
  const { setListCommissions } = useContextComissoes();
  const { handleGetAlert } = useLayoutMainContext();

  const handleGet = async (dataFilter: IHandleGet) => {
    setLoadingTable(true);

    api
      .get(
        `/commissions?page=${dataFilter.currentPage}&pageSize=${dataFilter.itensPerPage}&filter=${dataFilter.nameSearch}`
      )
      .then((res) => {
        setListCommissions(res.data.data);
        setQtdRegisters(res.data.meta.total);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingTable(false));
  };

  return { handleGet };
};
