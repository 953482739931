import { ChangeEvent } from "react";
import { formatString } from "@format-string/core";
import { Box } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { IValuesFilterContracts } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { valuesDefaultFilterContrato } from "src/Contexts/Lancamentos/Contrato/valuesDefaultInputsContratos/ValueDefaultInputContratos";
import { AppTextField } from "src/Pages/components";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";
import { ModalFilterCore } from "src/Pages/components/ModalFilterCore/Index";

import { useHttpTableContratos } from "../Table/Hooks/useHttpTableContratos";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";

export const FilterSidebar = () => {
  const { setValuesFilterContrato, valuesFilterContrato, setActiveCard } =
    useContextContrato();

  const { setAttTable } = useConfigPageContext();

  const { handleGet } = useHttpTableContratos();

  const handleChange =
    (prop: keyof IValuesFilterContracts, option?: "CPF_CNPJ") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "CPF_CNPJ") {
        setValuesFilterContrato({
          ...valuesFilterContrato,
          [prop]: formatString({
            type: "cpfOurCnpj",
            value: event.target.value,
          }),
        });
      } else {
        setValuesFilterContrato({
          ...valuesFilterContrato,
          [prop]: event.target.value,
        });
      }
    };

  const ClearFilter = async () => {
    setAttTable((item) => !item);
    setActiveCard(0);
    setValuesFilterContrato(valuesDefaultFilterContrato);
  };

  const FilterSearch = async () => {
    await handleGet();
  };

  return (
    <ModalFilterCore FilterClear={ClearFilter} FilterSearch={FilterSearch}>
      <DividerCore sxStyle={{ marginBottom: "12px" }} title="Proposta" />
      <Box
        id="container propostas"
        sx={{
          "& > .MuiAutocomplete-root, & > .MuiTextField-root": {
            flexWrap: "wrap",
            display: "flex",
            marginBottom: "1rem",
          },
        }}
      >
        <AppTextField
          label="Código da proposta"
          sxStyle={{ width: "100%" }}
          value={valuesFilterContrato.proposalCode}
          onChange={handleChange("proposalCode")}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
            alignItems: "center",
          }}
        >
          <AppTextField
            label="Data assinatura"
            type="date"
            value={valuesFilterContrato.startDateSignature}
            onChange={handleChange("startDateSignature")}
          />
          até
          <AppTextField
            label="Data assinatura"
            type="date"
            value={valuesFilterContrato.endDateSignature}
            onChange={handleChange("endDateSignature")}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
            alignItems: "center",
          }}
        >
          <AppTextField
            label="Data vencimento"
            type="date"
            value={valuesFilterContrato.startDateExpiration}
            onChange={handleChange("startDateExpiration")}
          />
          até
          <AppTextField
            label="Data vencimento"
            type="date"
            value={valuesFilterContrato.endDateExpiration}
            onChange={handleChange("endDateExpiration")}
          />
        </Box>
      </Box>

      <DividerCore sxStyle={{ marginBottom: "12px" }} title="Cliente (PF/PJ)" />
      <Box
        id="container cliente (PF/PJ)"
        sx={{
          "& > .MuiAutocomplete-root, & > .MuiTextField-root": {
            flexWrap: "wrap",
            display: "flex",
            marginBottom: "1rem",
          },
        }}
      >
        <AppTextField
          label="Titular/Razão"
          value={valuesFilterContrato.holderNameAndCompanyName}
          onChange={handleChange("holderNameAndCompanyName")}
        />
        <AppTextField
          label="CPF/CNPJ"
          value={valuesFilterContrato.cpfAndCNPJ}
          onChange={handleChange("cpfAndCNPJ", "CPF_CNPJ")}
        />
      </Box>
    </ModalFilterCore>
  );
};
