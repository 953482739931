import {
  IInterestLevelLeads,
  ISourceCollectionLeads,
  IStatusLeads,
} from "src/Contexts/Leads/LeadsTypes";

export const objGenro = {
  NONE: "' '",
  MALE: "Masculino",
  FEMALE: "Feminino",
  OTHER: "Outros",
};

export const objMaritalStatus = {
  NONE: "' '",
  SINGLE: "Solteiro",
  MARRIED: "Casado",
  SEPARATE: "Separado",
  DIVORCED: "Divorciado",
  WIDOWER: "Viúvo",
};

export const objLeadsStatus: Record<IStatusLeads, string> = {
  NEW: "Novo",
  IN_CONTACT: "Em contato",
  PROPOSAL_SENT: "Proposta enviada",
  PROGRESS: "Em andamento",
  LOST: "Perdido",
  FUTURE_RECONTACT: "Contato futuro",
  DISQUALIFIED: "Desqualificado",
};

export const objLeadsSourceCollection: Record<ISourceCollectionLeads, string> =
  {
    FACEBOOK: "Facebook",
    INSTAGRAM: "Instagram",
    LINKEDIN: "LinkeDin",
    GOOGLE: "Google",
    INDICATION: "Indicação",
    EMAIL_MARKETING: "Email-Marketing",
    MARKETING: "Marketing",
    FORM: "Formulários",
    LADING_PAGE: "Landing page",
  };

export const OBjLeadsInterestLevel: Record<
  IInterestLevelLeads,
  {
    title: string;
    color: string;
  }
> = {
  LOW: {
    title: "Baixo",
    color: "#ff1e00",
  },
  MEDIUM: {
    title: "Médio",
    color: "#ff8800",
  },
  HIGH: {
    title: "Alto",
    color: "#10c200",
  },
};
