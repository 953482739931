import { useEffect, useState } from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useContextViews } from "src/Contexts/Views/contextViews";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import {
  DescriptionIcon,
  FileOpenIcon,
  PeopleIcon,
  PercentIcon,
} from "src/Pages/components/Icons/Icons";
import { UrlConfig } from "src/shared/Utils/paths";

import { AsideMenuLeft } from "./AsideMenuLeft";
import { BaseMenuAside } from "./componentes/BaseMenuAside";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { MessageOutlined } from "@mui/icons-material";

interface ICollapseState {
  open4: boolean;
  open5: boolean;
  open6: boolean;
  open8: boolean;
}

export const AsideTitle = () => {
  const { openAside, setOpenAside } = useLayoutMainContext();
  const { NavigationRelations, NavigationComissoes } = useContextViews();

  const [collapseState, setCollapseState] = useState<ICollapseState>({
    open4: false,
    open5: false,
    open6: false,
    open8: false,
  });

  const [prevState, setPrevState] = useState<ICollapseState>({
    open4: false,
    open5: false,
    open6: false,
    open8: false,
  });

  useEffect(() => {
    if (!openAside) {
      setPrevState({ ...collapseState });
      setCollapseState({
        open4: false,
        open5: false,
        open6: false,
        open8: false,
      });
    } else {
      setCollapseState({ ...prevState });
    }
  }, [openAside]);

  const handleToggle = (key: keyof ICollapseState) => {
    setCollapseState((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
    setOpenAside(true);
  };

  return (
    <>
      <AsideMenuLeft
        icon={<BarChartIcon />}
        title="Dashboard"
        to={UrlConfig.dashboard.url}
      />

      <AsideMenuLeft
        icon={<PeopleIcon />}
        title="Leads"
        to={UrlConfig.leads.leads.url}
      />
      <AsideMenuLeft
        icon={<MessageOutlined />}
        title="Campanha"
        disabled
        to={UrlConfig.campaign.campaign.url}
        tooltip="Em desenvolvimento"
      />

      <BaseMenuAside
        Navigation={NavigationComissoes}
        icon={<PercentIcon />}
        open={collapseState.open5}
        setOpen={() => handleToggle("open5")}
        title={UrlConfig.comissao.title}
      />
      <AsideMenuLeft
        icon={<FindInPageIcon />}
        title="Contratos"
        to={UrlConfig.lancamentos.contratos.url}
      />
      <AsideMenuLeft
        icon={<FileOpenIcon />}
        title="Parcelas"
        to={UrlConfig.lancamentos.parcelas.url}
      />

      <BaseMenuAside
        Navigation={NavigationRelations}
        icon={<DescriptionIcon />}
        open={collapseState.open8}
        setOpen={() => handleToggle("open8")}
        title={UrlConfig.relatoriosProducao.title}
      />
    </>
  );
};
