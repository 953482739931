import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Box, IconButton, Tab, Tabs } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { Contract } from "./Contract/Contract";
import { TableInstallments } from "./Installments/table/TableInstallments";
import { TableContractsFiles } from "./Files/Components/table/TableContractsFiles";

export const ContractInternal = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [value, setValue] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  if (!id) return null;

  return (
    <Box>
      <TabContext value={value}>
        <Tabs value={value} onChange={handleChange}>
          <IconButton aria-label="back">
            <ArrowBackIcon onClick={() => navigate(-1)} />
          </IconButton>

          <Tab label="Contrato" value="1" />
          <Tab label="Parcelas" value="2" />
          <Tab label="Arquivos" value="3" />
        </Tabs>

        <TabPanel sx={{ padding: 0 }} value="1">
          <Contract idContract={id} />
        </TabPanel>

        <TabPanel sx={{ padding: 0 }} value="2">
          <TableInstallments idContract={id} />
        </TabPanel>

        <TabPanel sx={{ padding: 0 }} value="3">
          <TableContractsFiles idContract={id} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
