import { IDataTh, ThCoreIndex } from "src/Pages/components";

export const THeadLeads = () => {
  const dataTh: IDataTh[] = [
    {
      title: "Ações",
      width: "40px",
      align: "left",
    },
    {
      title: "Nome completo",
      align: "left",
      width: "250px",
      minWidth: "250px",
    },
    {
      title: "Whats/Telefone",
      align: "left",
      width: "100px",
      minWidth: "100px",
    },
    {
      title: "Nível de interesse",
      align: "left",
      width: "120px",
      minWidth: "120px",
    },
    {
      title: "Status",
      align: "left",
      width: "100px",
      minWidth: "100px",
    },
    {
      title: "Fonte",
      align: "left",
      width: "100px",
      minWidth: "100px",
    },
    {
      title: "Data de captura",
      align: "left",
      width: "150px",
      minWidth: "150px",
    },
  ];

  return <ThCoreIndex dataTh={dataTh} />;
};
