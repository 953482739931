import { createContext, useContext, ReactNode, useState, FC } from "react";

import { IListLeads, IContextLeads, IInputCreateLeads } from "./LeadsTypes";
import { valuesDefaultInputLeads } from "./valuesDefaultInputsLeads/ValueDefaultInputLeads";

const LeadsContext = createContext({} as IContextLeads);

export const LeadsContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [listLeads, setListLeads] = useState([] as IListLeads[]);
  const [dataGetByIdLeads, setDataGetByIdLeads] = useState<IListLeads>();

  const [valuesInputsLeads, setValuesInputsLeads] = useState<IInputCreateLeads>(
    valuesDefaultInputLeads
  );

  return (
    <LeadsContext.Provider
      value={{
        setValuesInputsLeads,
        setDataGetByIdLeads,
        valuesInputsLeads,
        dataGetByIdLeads,
        setListLeads,
        listLeads,
      }}
    >
      {children}
    </LeadsContext.Provider>
  );
};

export const useContextLeads = (): IContextLeads => useContext(LeadsContext);
