import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextLeads } from "src/Contexts/Leads/LeadsContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const useHttpTableLeads = () => {
  const { setListLeads } = useContextLeads();
  const { handleGetAlert } = useLayoutMainContext();

  const {
    setQtdRegisters,
    setLoadingTable,
    itensPerPage,
    currentPage,
    nameSearch,
  } = useConfigPageContext();

  const handleGet = async () => {
    setLoadingTable(true);

    api
      .get(
        `/leads?page=${currentPage}&pageSize=${itensPerPage}&filter=${nameSearch}`
      )
      .then((res) => {
        setListLeads(res.data.data);
        setQtdRegisters(res.data.meta.total);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingTable(false));
  };

  return { handleGet };
};
