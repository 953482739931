import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { CardCopy } from "./CardCopy/CardCopy";
import { CardPlan } from "./CardPlan/CardPlan";
import { TablePayments } from "./TablePayments/TablePayments";
import { PlanActive } from "./PlanActive/Index";

export const PageSubscriptions = () => {
  const { openAside } = useLayoutMainContext();
  const { userPerfil } = useAuthContext();
  const theme = useTheme();

  const isBreakPoint = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      {userPerfil?.subscription?.id ? (
        <PlanActive />
      ) : (
        <Grid container spacing={2} mb={4}>
          <Grid item xs={12} md={openAside && isBreakPoint ? 12 : 3}>
            <CardCopy />
          </Grid>

          <Grid item xs={12} md={openAside && isBreakPoint ? 12 : 9}>
            <CardPlan />
          </Grid>
        </Grid>
      )}

      <TablePayments />
    </>
  );
};
