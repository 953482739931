import React, { FC, ReactNode, useEffect, useRef } from "react";
import {
  LinearProgress,
  TableContainer,
  Typography,
  SxProps,
  Table,
  Paper,
  alpha,
  Box,
} from "@mui/material";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";

import { useStyles } from "../../ModalFilterCore/Index";
import { JoinPaginationAndSelect } from "../JoinPaginationAndSelect/JoinPaginationAndSelect";
import { TrNotList } from "../TrNotList/TrNotList";
import { HeaderTable } from "../HeaderTable/HeaderTable";
import { InputSearchPersonalized } from "../JoinPaginationAndSelect/InpuSearchPersonalized/InpuSearchPersonalized";

type ILoadingTable = {
  heightTable?: string;
  qtdList: number;
  isNotPagination?: boolean;
  THead?: ReactNode;
  filter?: ReactNode;
  setNameSearch?: React.Dispatch<React.SetStateAction<string>>;
  isLastChildren?: boolean;
  title?: string;

  cardAdd?: {
    title?: string;
    buttons?: ReactNode[];
    icon?: ReactNode;
  };
  Modals?: ReactNode;
  sxStyle?: SxProps;
  children: ReactNode;
};

export const TableCore: FC<ILoadingTable> = ({
  isNotPagination,
  isLastChildren,
  setNameSearch,
  heightTable,
  children,
  cardAdd,
  sxStyle,
  title,
  qtdList,
  filter,
  THead,
  Modals,
}) => {
  const { loadingTable, qtdRegisters, currentPage, setCurrentPage } =
    useConfigPageContext();

  const tabelaRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (Number(qtdList) === 0) {
      if (currentPage > 1) {
        setCurrentPage((item) => item - 1);
      }
    }
  }, [qtdRegisters]);

  const classes = useStyles();

  const handleGoLastChildren = () => {
    const tabela = tabelaRef.current as HTMLTableElement;

    tabela.scrollTop = tabela.scrollHeight - tabela.clientHeight;
  };

  useEffect(() => {
    if (isLastChildren && !loadingTable && qtdList) {
      handleGoLastChildren();
    }
  }, [loadingTable]);

  return (
    <Box sx={{ ...sxStyle }}>
      {Modals}

      {loadingTable && (
        <Box style={{ width: "100%" }}>
          <LinearProgress color="inherit" />
        </Box>
      )}

      <Paper
        elevation={2}
        sx={{ border: `1px solid ${alpha("#b6b6b6", 0.3)}` }}
      >
        {cardAdd && (
          <Box
            sx={{
              padding: { xs: ".7rem", sm: "20px" },
              display: { sm: "flex" },
              alignItems: "center",
              justifyContent: "space-between",
              textAlign: "center",
            }}
          >
            {title && <Typography>{title}</Typography>}

            <Box
              sx={{
                display: "flex",
                flexDirection: "",
                textAlign: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {setNameSearch && (
                <InputSearchPersonalized setNameSearch={setNameSearch} />
              )}

              {filter && filter}
            </Box>

            <HeaderTable buttonAdd={cardAdd.buttons} />
          </Box>
        )}
        <TableContainer
          ref={tabelaRef}
          className={classes.customScrollbar}
          sx={{
            maxHeight: heightTable || "500px",
            borderRadius: "12px",
            overflow: "auto",
          }}
        >
          <Table>
            {THead && THead}

            <tbody>{children}</tbody>
          </Table>
          <TrNotList loadingTable={!loadingTable} qtdList={qtdList} />
        </TableContainer>
      </Paper>

      {isNotPagination !== true && (
        <JoinPaginationAndSelect qtd_registros={qtdRegisters} />
      )}
    </Box>
  );
};
