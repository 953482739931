import { useCallback } from "react";

import { alpha, Box, Typography } from "@mui/material";
import { CardPreviewFile } from "../../../../../../components/CardPreviewFile/CardPreviewFile";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import {
  DropZoneCore,
  IListPDF,
} from "src/Pages/components/DropZoneCore/DropZoneCore";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";

export const FormContractsFiles = () => {
  const { setUploadFiles, uploadFiles } = useContextContrato();

  const handleDelete = useCallback((deletedName: string) => {
    setUploadFiles((prevList) =>
      prevList.filter((file) => file.id !== deletedName)
    );
  }, []);

  const handleUploadFile = useCallback(async (files: IListPDF[]) => {
    setUploadFiles((prevList) => [...files, ...prevList]);
  }, []);

  return (
    <>
      <DropZoneCore
        onFilesChange={handleUploadFile}
        accept={{
          "image/jpeg": [],
          "image/png": [],
          "image/JPG": [],
          "image/WEBP": [],
          "image/GIF": [],

          "application/pdf": [],
          "application/msword": [],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [],
          "application/vnd.ms-excel": [],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            [],
          "text/plain": [],
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            marginBottom: "16px",
            background: (theme) => alpha(theme.palette.primary.main, 0.3),
          }}
        >
          <DriveFolderUploadIcon fontSize="large" />
        </Box>

        <Typography>
          <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
            Clique aqui
          </Typography>{" "}
          ou arraste seu arquivo
        </Typography>
      </DropZoneCore>

      <Box>
        {uploadFiles.map((item) => {
          return (
            <CardPreviewFile
              key={item.name}
              type={item.type as "image/jpeg"}
              size={item.size}
              title={item.name}
              url={item.previewUrl}
              onClick={() => handleDelete(item.id)}
            />
          );
        })}
      </Box>
    </>
  );
};
