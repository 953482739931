import { IDataTh, ThCoreIndex } from "src/Pages/components";

export const THeadInstallments = () => {
  const dataTh: IDataTh[] = [
    {
      title: "Ações",
      align: "left",
      width: "40px",
    },
    {
      title: "Operadoras",
      align: "left",
      minWidth: "120px",
      width: "120px",
    },
    {
      title: "Proposta",
      align: "left",
      width: "120px",
      minWidth: "120px",
    },
    {
      title: "Parcelas",
      align: "center",
      width: "90px",
    },
    {
      title: "D. vencimento",
      align: "center",
      width: "100px",
      minWidth: "120px",
    },
    {
      title: "R$ Mensalidade ",
      tooltip: "Valor Mensalidade",
      align: "left",
      width: "100px",
      minWidth: "130px",
    },
    {
      title: "D. Recebimento",
      tooltip: "Data do recebimento",
      align: "center",
      width: "100px",
      minWidth: "130px",
    },
    {
      title: "R$ Contrato",
      tooltip: "Valor do contrato",
      align: "left",
      width: "100px",
      minWidth: "110px",
    },
    {
      title: "Lucro",
      tooltip: "Valor que lucrou",
      align: "left",
      width: "100px",
      minWidth: "100px",
    },
    {
      title: "% Comissão",
      tooltip: "Porcentagem que recebe",
      align: "center",
      width: "120px",
      minWidth: "120px",
    },
    {
      title: "Titular/Razão",
      align: "left",
      width: "200px",
      minWidth: "150px",
    },
  ];

  return <ThCoreIndex dataTh={dataTh} />;
};
