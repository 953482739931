import {
  createContext,
  useContext,
  useEffect,
  ReactNode,
  useState,
  FC,
} from "react";
import {
  PageInstallmentsAll,
  PageCommissions,
  PageContracts,
} from "src/Pages/TelasSistemas";
import { PageHelp } from "src/Pages/TelasSistemas/Help/PageHelp";
import { PagePerfil } from "src/Pages/TelasSistemas/Perfil";
import { PagePlansConfig } from "src/Pages/TelasSistemas/Plans";
import { PageReportConfirmationProducer } from "src/Pages/TelasSistemas/Relatorios/Confirmation/ConfirmationProducer";
import { PageReportProductionContract } from "src/Pages/TelasSistemas/Relatorios/producao/ProductionContract";
import { PageReportProductionOperator } from "src/Pages/TelasSistemas/Relatorios/producao/ProductionOperator";
import { UrlConfig } from "src/shared/Utils/paths";

import { useAuthContext } from "../AuthContext/authContext";
import { IViews } from "../types/ProdutoresContext.Types";

import { INavigationContext } from "./ViewsTypes";
import { PageCadastro } from "src/Pages/TelasSistemas/Commissions/Cadastros/Index";
import { ContractInternal } from "src/Pages/TelasSistemas/Contracts/ContractInternal";
import { PageDashboardBroker } from "src/Pages/TelasSistemas/DashboardBroker/PageDashboard";
import { PageLeads } from "src/Pages/TelasSistemas/Leads";

const ViewsProvider = createContext({} as INavigationContext);

export const ViewsContext: FC<{ children: ReactNode }> = ({ children }) => {
  const { userPerfil } = useAuthContext();

  const [NavigationInterno, setNavigationInterno] = useState<IViews[]>([]);
  const [NavigationComissoes, setNavigationComissoes] = useState<IViews[]>([]);
  const [NavigationRelations, setNavigationRelations] = useState<IViews[]>([]);
  const [NavigatioPaymentsPlans, setNavigatioPaymentsPlans] = useState<
    IViews[]
  >([]);

  const [NavigationLancamentos, setNavigationLancamentos] = useState<IViews[]>(
    []
  );
  // colocar o <Page> dentro do urlConfig e usar dentro do map para n precisar passar aqui do obj e talvez nem precise desse context

  const [NavigatioPerfil] = useState<IViews[]>([
    {
      element: <PagePerfil />,
      route: UrlConfig.profile,
      isView: true,
    },
    {
      element: <PageHelp />,
      route: UrlConfig.help,
      isView: true,
    },

    {
      element: <PageDashboardBroker />,
      route: UrlConfig.dashboard,
      isView: true,
    },
    {
      element: <PageLeads />,
      route: UrlConfig.leads.leads,
      isView: true,
    },
  ]);

  useEffect(() => {
    setNavigationComissoes([
      {
        element: <PageCadastro />,
        isView: true,
        route: UrlConfig.comissao.cadastros,
      },
      {
        isView: true,
        route: UrlConfig.comissao.corretora,
        element: <PageCommissions />,
      },
    ]);

    setNavigationLancamentos([
      {
        isView: true,
        route: UrlConfig.lancamentos.contratos,
        element: <PageContracts />,
      },
      {
        isView: true,
        route: UrlConfig.lancamentos.parcelas,
        element: <PageInstallmentsAll />,
      },
    ]);

    setNavigationInterno([
      {
        element: <ContractInternal />,
        isView: true,
        route: UrlConfig.lancamentos.contrato,
      },
    ]);

    setNavigatioPaymentsPlans([
      {
        element: <PagePlansConfig />,
        isView: true,
        route: UrlConfig.plans,
      },
    ]);

    setNavigationRelations([
      {
        isDevelop: true,
        isView: true,
        route: UrlConfig.relatoriosProducao.producaoContract,
        element: <PageReportProductionContract />,
      },

      {
        isDevelop: true,
        isView: true,
        route: UrlConfig.relatoriosProducao.producaoOperator,
        element: <PageReportProductionOperator />,
      },

      {
        isDevelop: true,
        isView: true,
        route: UrlConfig.relatoriosProducao.confirmationProducer,
        element: <PageReportConfirmationProducer />,
      },
    ]);
  }, [userPerfil?.email]);

  return (
    <ViewsProvider.Provider
      value={{
        NavigatioPaymentsPlans,
        NavigationLancamentos,
        NavigationComissoes,
        NavigationRelations,
        NavigationInterno,
        NavigatioPerfil,
      }}
    >
      {children}
    </ViewsProvider.Provider>
  );
};

export const useContextViews = (): INavigationContext =>
  useContext(ViewsProvider);
