import { Tooltip } from "@mui/material";
import { UrlConfig } from "src/shared/Utils/paths";
import { LinkCore } from "src/Pages/components/LinkCore/LinkCore";
import { Logo } from "src/Pages/components/Logo/Logo";

export const LogoHeader = () => {
  return (
    <Tooltip title={UrlConfig.dashboard.title}>
      <LinkCore to={UrlConfig.dashboard.url}>
        <Logo />
      </LinkCore>
    </Tooltip>
  );
};
