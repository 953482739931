import { Grid, MenuItem } from "@mui/material";
import { createRef, ChangeEvent, useEffect } from "react";

import { useContextLeads } from "src/Contexts/Leads/LeadsContext";
import { IInputCreateLeads } from "src/Contexts/Leads/LeadsTypes";
import { AppTextField } from "src/Pages/components/AppFormComponents/AppTextField";
import { cpfOurCnpj } from "src/shared/Utils/MaskCpfCnpj";

export const FormLeads = () => {
  const { valuesInputsLeads, setValuesInputsLeads } = useContextLeads();

  const refOrigem = createRef<HTMLInputElement>();
  const handleFocusedID = () => refOrigem.current?.focus();

  useEffect(() => {
    handleFocusedID();
  }, []);

  const handleChange =
    (prop: keyof IInputCreateLeads, option?: "cpf" | "phone") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "phone") {
        setValuesInputsLeads((eventPrev) => ({
          ...eventPrev,
          [prop]: cpfOurCnpj({ text: event.target.value, type: "phone" }),
        }));
      } else if (option === "cpf") {
        setValuesInputsLeads((eventPrev) => ({
          ...eventPrev,
          [prop]: cpfOurCnpj({ text: event.target.value, type: "cpf" }),
        }));
      } else {
        setValuesInputsLeads((eventPrev) => ({
          ...eventPrev,
          [prop]: event.target.value,
        }));
      }
    };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <AppTextField
          fullWidth
          inputRef={refOrigem}
          label="Nome completo *"
          placeholder="João da Silva"
          value={valuesInputsLeads?.name}
          onChange={handleChange("name")}
        />
      </Grid>

      <Grid item xs={12}>
        <AppTextField
          fullWidth
          label="Email"
          placeholder="lead@lead.com"
          value={valuesInputsLeads?.email}
          onChange={handleChange("email")}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <AppTextField
          fullWidth
          label="Whats"
          placeholder="(11) 9-8070-1020"
          value={valuesInputsLeads?.whats}
          onChange={handleChange("whats", "phone")}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AppTextField
          fullWidth
          label="Telefone"
          placeholder="(11) 9-8070-1020"
          value={valuesInputsLeads?.telefone1}
          onChange={handleChange("telefone1", "phone")}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <AppTextField
          fullWidth
          select
          label="Nível de interesse *"
          value={valuesInputsLeads?.interestLevel}
          onChange={handleChange("interestLevel")}
        >
          <MenuItem value="HIGH">Alto</MenuItem>
          <MenuItem value="MEDIUM">Médio</MenuItem>
          <MenuItem value="LOW">Baixo</MenuItem>
        </AppTextField>
      </Grid>

      <Grid item xs={6} md={4}>
        <AppTextField
          fullWidth
          select
          label="Status lead *"
          value={valuesInputsLeads?.status}
          onChange={handleChange("status")}
        >
          <MenuItem value="NEW">Novo</MenuItem>
          <MenuItem value="IN_CONTACT">Em contato</MenuItem>
          <MenuItem value="PROPOSAL_SENT">Proposta enviada</MenuItem>
          <MenuItem value="PROGRESS">Em andamento</MenuItem>
          <MenuItem value="FUTURE_RECONTACT">Futuro contato</MenuItem>
          <MenuItem value="DISQUALIFIED">Desqualificado</MenuItem>
          <MenuItem value="LOST">Perdido</MenuItem>
        </AppTextField>
      </Grid>

      <Grid item xs={12} md={4}>
        <AppTextField
          fullWidth
          select
          label="Fonte do lead *"
          value={valuesInputsLeads?.sourceCollection}
          onChange={handleChange("sourceCollection")}
        >
          <MenuItem value="INDICATION">Indicação</MenuItem>
          <MenuItem value="GOOGLE">Google</MenuItem>
          <MenuItem value="FORM">Formulários</MenuItem>
          <MenuItem value="LADING_PAGE">Landing Page</MenuItem>

          <MenuItem value="FACEBOOK">FaceBook</MenuItem>
          <MenuItem value="INSTAGRAM">Instagram </MenuItem>
          <MenuItem value="LINKEDIN">LinkeDIn</MenuItem>

          <MenuItem value="EMAIL_MARKETING">Email</MenuItem>
          <MenuItem value="MARKETING">Marketing</MenuItem>
        </AppTextField>
      </Grid>

      <Grid item xs={12} md={6}>
        <AppTextField
          fullWidth
          label="CPF"
          placeholder="760.400.145.58"
          value={valuesInputsLeads?.cpf}
          onChange={handleChange("cpf", "cpf")}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <AppTextField
          fullWidth
          type="date"
          label="Data de nascimento"
          value={valuesInputsLeads?.birthDate}
          onChange={handleChange("birthDate")}
        />
      </Grid>

      <Grid item xs={12}>
        <AppTextField
          fullWidth
          label="Ação pendente"
          placeholder="Preciso enviar documentação"
          value={valuesInputsLeads?.pendingActions}
          onChange={handleChange("pendingActions")}
        />
      </Grid>

      <Grid item xs={12}>
        <AppTextField
          fullWidth
          label="Motivo da desistência"
          value={valuesInputsLeads?.reasonAbandonment}
          onChange={handleChange("reasonAbandonment")}
        />
      </Grid>

      <Grid item xs={12}>
        <AppTextField
          fullWidth
          label="Observação"
          value={valuesInputsLeads?.observation}
          onChange={handleChange("observation")}
        />
      </Grid>
    </Grid>
  );
};
