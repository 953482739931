import { ChangeEvent } from "react";
import { formatString } from "@format-string/core";
import { MenuItem, Grid } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ICreateContrato } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { AppTextField } from "src/Pages/components";
import { handleFormatCep } from "src/shared/Utils/FormatCEP";
import { apenasNumeros } from "src/shared/Utils/FormatMoney";
import { unidadesFederativas } from "src/shared/Utils/ListUFBR";

import { UseGetCEPContratos } from "./Hooks/UseGetCEPContratos";

export const FormContato = () => {
  const { valuesInputsContrato, setValuesInputsContrato } =
    useContextContrato();

  UseGetCEPContratos();

  const handleChange =
    (prop: keyof ICreateContrato, option?: "phone" | "apenasNumero" | "CEP") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "phone") {
        setValuesInputsContrato((eventPrev) => ({
          ...eventPrev,
          [prop]: formatString({ value: event.target.value, type: "phone" }),
        }));
      } else if (option === "CEP") {
        setValuesInputsContrato((eventPrev) => ({
          ...eventPrev,
          [prop]: handleFormatCep(event.target.value),
        }));
      } else if (option === "apenasNumero") {
        setValuesInputsContrato((eventPrev) => ({
          ...eventPrev,
          [prop]: apenasNumeros(event.target.value),
        }));
      } else
        setValuesInputsContrato((eventPrev) => ({
          ...eventPrev,
          [prop]: event.target.value,
        }));
    };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <AppTextField
          label="Email"
          fullWidth
          placeholder="cliente@cliente.com"
          value={valuesInputsContrato.email}
          onChange={handleChange("email")}
        />
      </Grid>
      <Grid item xs={6} lg={3}>
        <AppTextField
          label="Telefone 1"
          fullWidth
          placeholder="(11) 9-8070-1020"
          value={valuesInputsContrato.phone1}
          onChange={handleChange("phone1", "phone")}
        />
      </Grid>
      <Grid item xs={6} lg={3}>
        <AppTextField
          label="Telefone 2"
          fullWidth
          placeholder="(11) 9-8070-1020"
          value={valuesInputsContrato.phone2}
          onChange={handleChange("phone2", "phone")}
        />
      </Grid>

      <Grid item xs={6} lg={3}>
        <AppTextField
          label="CEP"
          fullWidth
          placeholder="21947-289"
          value={valuesInputsContrato.postalCode}
          onChange={handleChange("postalCode", "CEP")}
        />
      </Grid>
      <Grid item xs={6} lg={2}>
        <AppTextField
          label="Número"
          fullWidth
          value={valuesInputsContrato.number}
          onChange={handleChange("number")}
        />
      </Grid>
      <Grid item xs={12} lg={5}>
        <AppTextField
          label="Endereço"
          fullWidth
          value={valuesInputsContrato.street}
          onChange={handleChange("street")}
        />
      </Grid>
      <Grid item xs={6} lg={2}>
        <AppTextField
          fullWidth
          select
          label="UF"
          value={valuesInputsContrato?.state}
          onChange={handleChange("state") as never}
        >
          {unidadesFederativas.map((item) => {
            return (
              <MenuItem key={item.sigla} value={item.sigla}>
                {item.nome}
              </MenuItem>
            );
          })}
        </AppTextField>
      </Grid>

      <Grid item xs={6} lg={3}>
        <AppTextField
          label="Cidade"
          fullWidth
          value={valuesInputsContrato.city}
          onChange={handleChange("city")}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <AppTextField
          label="Bairro"
          fullWidth
          value={valuesInputsContrato.neighborhood}
          onChange={handleChange("neighborhood")}
        />
      </Grid>
      <Grid item xs={12} lg={5}>
        <AppTextField
          label="Complemento"
          fullWidth
          value={valuesInputsContrato.complement}
          onChange={handleChange("complement")}
        />
      </Grid>
    </Grid>
  );
};
