import { createRef, ChangeEvent, useEffect } from "react";

import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { ICreateEmpresas } from "src/Contexts/comissoes/ComissoesTypes";
import { AppTextField } from "src/Pages/components/AppFormComponents/AppTextField";

export const FormEmpresas = () => {
  const { valuesInputsEmpresas, setValuesInputsEmpresas } =
    useContextComissoes();

  const refOrigem = createRef<HTMLInputElement>();
  const handleFocusedID = () => refOrigem.current?.focus();

  useEffect(() => {
    handleFocusedID();
  }, []);

  const handleChange =
    (prop: keyof ICreateEmpresas) => (event: ChangeEvent<HTMLInputElement>) => {
      setValuesInputsEmpresas((eventPrev) => ({
        ...eventPrev,
        [prop]: event.target.value,
      }));
    };

  return (
    <AppTextField
      fullWidth
      inputRef={refOrigem}
      label="Empresa *"
      value={valuesInputsEmpresas?.name}
      onChange={handleChange("name")}
    />
  );
};
