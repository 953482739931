import React, { useEffect } from "react";
import { useResetInputs } from "src/Contexts";
import { ModalCore } from "src/Pages/components";

import { FormContractsFiles } from "./FormContractsFiles";
import { UseFormContractsFiles } from "./Hook/UseFeaturesFormulario";

interface IModalContractsFiles {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
}

export const ModalContractsFiles = ({
  openModal,
  setOpenModal,
  id,
}: IModalContractsFiles) => {
  const { resetInputs } = useResetInputs();

  const { handleSubmit, loading: loadingSubmit } = UseFormContractsFiles({
    setOpenModal,
    idContract: id,
  });

  useEffect(() => {
    if (!openModal) resetInputs();
  }, [openModal]);

  return (
    <ModalCore
      loadingSubmit={loadingSubmit}
      open={openModal}
      setOpen={setOpenModal}
      sxStyle={{ width: "600px" }}
      title="Arquivos"
      titleSubmit="Salvar"
      onChange={handleSubmit}
    >
      <FormContractsFiles />
    </ModalCore>
  );
};
