import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextLeads } from "src/Contexts/Leads/LeadsContext";
import { IInputCreateLeads } from "src/Contexts/Leads/LeadsTypes";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";
import { apenasNumeros } from "src/shared/Utils/FormatMoney";
import { handleFormatDateIfIsNull } from "src/shared/Utils/handleFormatDateIfIsNull";

interface IUseFormOperadora {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFormLeads = ({ setOpenModal }: IUseFormOperadora) => {
  const { valuesInputsLeads } = useContextLeads();
  const { setAttTable, id } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const resultData: IInputCreateLeads = {
    ...valuesInputsLeads,

    cpf: apenasNumeros(valuesInputsLeads.cpf) || "",

    telefone1: apenasNumeros(valuesInputsLeads.telefone1) || "",
    whats: apenasNumeros(valuesInputsLeads.whats) || "",

    birthDate: handleFormatDateIfIsNull(valuesInputsLeads.birthDate),
  };

  const handleCreate = async () => {
    if (!resultData.name?.trim())
      return handleGetAlert({ message: `Digite um nome` });

    setLoading(true);

    return api
      .post("/leads", resultData)
      .then((res) => {
        setAttTable((item) => !item);
        handleGetAlert({ message: res.data.message });
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleEdit = () => {
    if (!resultData.name?.trim())
      return handleGetAlert({ message: `Digite um nome` });

    setLoading(true);

    return api
      .put(`/leads/${id}`, resultData)
      .then((res) => {
        setAttTable((item) => !item);
        handleGetAlert({ message: res.data.message });
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleSubmit = () => {
    if (id) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  return { handleSubmit, loading };
};
