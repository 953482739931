import React, { useEffect } from "react";
import { useResetInputs } from "src/Contexts";
import { ModalCore } from "src/Pages/components";

import { FormLeads } from "./FormLeads";
import { UseFormLeads } from "./Hook/UseFormLeads";

interface IModalLeads {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalLeads = ({ openModal, setOpenModal }: IModalLeads) => {
  const { resetInputs } = useResetInputs();

  const { handleSubmit, loading: loadingSubmit } = UseFormLeads({
    setOpenModal,
  });

  useEffect(() => {
    if (!openModal) resetInputs();
  }, [openModal]);

  return (
    <ModalCore
      loadingSubmit={loadingSubmit}
      open={openModal}
      setOpen={setOpenModal}
      sxStyle={{ width: "600px" }}
      title="Leads"
      titleSubmit="Salvar"
      onChange={handleSubmit}
    >
      <FormLeads />
    </ModalCore>
  );
};
