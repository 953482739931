import { Grid, useTheme } from "@mui/material";
import { useEffect } from "react";
import { CardTable } from "src/Pages/components/table/Cards/CardTable";
import { UseMetricsDashboard } from "./Hooks/UseMetricsDashboard";
import { useContextDashboardContext } from "src/Contexts/Dashboard/ContextDashboardBroker";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { FormatDateDayLastMonth } from "src/shared/Utils/FormatDate/FormatDateDayLastMonth";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { valuesDefaultFilterContrato } from "src/Contexts/Lancamentos/Contrato/valuesDefaultInputsContratos/ValueDefaultInputContratos";

export const CardHeaderContracts = () => {
  const { metricsDashboard } = useContextDashboardContext();
  const { setAttTable, attTable } = useConfigPageContext();
  const { handleSubmit } = UseMetricsDashboard();
  const theme = useTheme();

  const { setValuesFilterContrato, setActiveCard, activeCard } =
    useContextContrato();

  useEffect(() => {
    handleSubmit();
  }, [attTable]);

  const handleCardClick = (index: number) => {
    if (index === activeCard) return setActiveCard(0);
    setActiveCard(index);
  };

  return (
    <Grid container spacing={2} sx={{ marginBottom: "32px" }}>
      <Grid xs={12} sm={4} item>
        <CardTable
          icon={{ color: theme.palette.primary.main, icon: <FileCopyIcon /> }}
          isActive={activeCard === 1}
          onClick={() => {
            handleCardClick(1);
            setAttTable((item) => !item);

            setValuesFilterContrato(() => ({
              ...valuesDefaultFilterContrato,
              startDateSignature:
                activeCard !== 1 ? FormatDateDayLastMonth().firstDayMonth : "",
              endDateSignature:
                activeCard !== 1 ? FormatDateDayLastMonth().lastDayMonth : "",
            }));
          }}
          title="Assinatura esse mês"
          value1={String(metricsDashboard.contracts?.count) || "0"}
          value2={`${metricsDashboard.contracts?.percentLast} últimos ${metricsDashboard.meta?.countDaysLast} dias`}
        />
      </Grid>

      <Grid xs={12} sm={4} item>
        <CardTable
          icon={{ color: theme.palette.primary.main, icon: <FileCopyIcon /> }}
          isActive={activeCard === 2}
          onClick={() => {
            handleCardClick(2);
            setAttTable((item) => !item);

            setValuesFilterContrato(() => ({
              ...valuesDefaultFilterContrato,
              startDateExpiration:
                activeCard !== 2 ? FormatDateDayLastMonth().firstDayMonth : "",
              endDateExpiration:
                activeCard !== 2 ? FormatDateDayLastMonth().lastDayMonth : "",
            }));
          }}
          title="Vence esse mês"
          value1={String(metricsDashboard.contractExpiration?.count) || "0"}
          value2={`${metricsDashboard.contractExpiration?.percentLast} últimos ${metricsDashboard.meta?.countDaysLast} dias`}
        />
      </Grid>

      <Grid xs={12} sm={4} item>
        <CardTable
          icon={{ color: theme.palette.primary.main, icon: <FileCopyIcon /> }}
          isActive={activeCard === 3}
          onClick={() => {
            handleCardClick(3);
            setAttTable((item) => !item);
            setValuesFilterContrato(() => ({
              ...valuesDefaultFilterContrato,
              status: activeCard !== 3 ? "CANCELED" : undefined,
            }));
          }}
          title="Cancelados"
          value1={String(metricsDashboard.contractsCanceled?.count) || "0"}
          value2={`${metricsDashboard.contractsCanceled?.percentLast} últimos ${metricsDashboard.meta?.countDaysLast} dias`}
        />
      </Grid>
    </Grid>
  );
};
