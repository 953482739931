import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";
import { apenasNumeros } from "src/shared/Utils/FormatMoney";

export const useHttpTableContratos = () => {
  const { setListContratos, valuesFilterContrato } = useContextContrato();
  const { handleGetAlert } = useLayoutMainContext();

  const {
    setQtdRegisters,
    setLoadingTable,
    itensPerPage,
    currentPage,
    nameSearch,
  } = useConfigPageContext();

  const handleGet = async () => {
    setLoadingTable(true);

    return api
      .get(
        `/contracts?page=${currentPage}&pageSize=${itensPerPage}&filter=${nameSearch}&subscriptionDateStart=${
          valuesFilterContrato.startDateSignature
        }&subscriptionDateEnd=${
          valuesFilterContrato.endDateSignature
        }&expirationDateStart=${
          valuesFilterContrato.startDateExpiration
        }&expirationDateEnd=${valuesFilterContrato.endDateExpiration}&${
          valuesFilterContrato.status && `status=${valuesFilterContrato.status}`
        }&proposalCode=${
          valuesFilterContrato.proposalCode
        }&holderNameAndCompanyName=${
          valuesFilterContrato.holderNameAndCompanyName
        }&cpfAndCNPJ=${apenasNumeros(valuesFilterContrato.cpfAndCNPJ)}`
      )
      .then((res) => {
        setListContratos(res.data.data);
        setQtdRegisters(res.data.meta.total);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingTable(false));
  };

  return { handleGet };
};
