import { ChangeEvent } from "react";
import { Grid } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { IReceivedInstallment } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { AppTextField } from "src/Pages/components/AppFormComponents/AppTextField";
import { FormatMoney } from "src/shared/Utils/FormatMoney";

export const FormReceivedInstallment = () => {
  const { valuesReceivedInstallment, setValuesReceivedInstallment } =
    useContextContrato();

  const handleChange =
    (prop: keyof IReceivedInstallment, option?: "money") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "money") {
        setValuesReceivedInstallment((prevValue) => ({
          ...prevValue,
          [prop]: FormatMoney(event.target.value),
        }));
      } else {
        setValuesReceivedInstallment((prevValue) => ({
          ...prevValue,
          [prop]: event.target.value,
        }));
      }
    };

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <AppTextField
          fullWidth
          label="Data recebimento *"
          type="date"
          value={valuesReceivedInstallment?.dateReceived}
          onChange={handleChange("dateReceived")}
        />
      </Grid>
      <Grid item xs={4.7}>
        <AppTextField
          fullWidth
          label="Valor mensalidade *"
          value={valuesReceivedInstallment?.valuePayment}
          onChange={handleChange("valuePayment", "money")}
        />
      </Grid>
      <Grid item xs={2.3}>
        <AppTextField
          fullWidth
          label="Vidas *"
          type="number"
          value={valuesReceivedInstallment.lives}
          onChange={handleChange("lives")}
        />
      </Grid>
      <Grid item xs={12}>
        <AppTextField
          fullWidth
          label="Observação"
          value={valuesReceivedInstallment.note}
          onChange={handleChange("note")}
        />
      </Grid>
    </Grid>
  );
};
