import { FC, ReactNode } from "react";
import { SxProps } from "@mui/material";
import { FormCore } from "src/Pages/components";
import { Logo } from "src/Pages/components/Logo/Logo";

interface IBasePageLogin {
  sxStyle?: SxProps;
  children: ReactNode;
}

export const BasePageLogin: FC<IBasePageLogin> = ({ children, sxStyle }) => {
  return (
    <FormCore
      sxStyle={{
        padding: { xs: "0" },
        margin: { sm: "0 auto" },
        alignItems: "center",
        width: { xs: "100%", lg: "500px" },
        ...sxStyle,
      }}
    >
      <Logo />
      {children}
    </FormCore>
  );
};
