import { useContextComissoes } from "../comissoes/ComissoesContext";
import { valuesDefaultInputsCommissions } from "../comissoes/ValuesDefaultInputsComissoes/ValuesDefaultInputsComissoes";
import { useConfigPageContext } from "../configPagesContext/configPagesContext";
import { useContextDashboardContext } from "../Dashboard/ContextDashboardBroker";
import { valueDefaultFilterMetricsDashboard } from "../Dashboard/ValuesDefaultContextProfile/ValuesDefaultContextDashboardBroker";
import { useContextContrato } from "../Lancamentos/Contrato/ContratosContext";
import {
  valueDefaultInputReceivedInstallments,
  valuesDefaultFilterContrato,
  valueDefaultInputContratos,
} from "../Lancamentos/Contrato/valuesDefaultInputsContratos/ValueDefaultInputContratos";
import { useContextLeads } from "../Leads/LeadsContext";
import { valuesDefaultInputLeads } from "../Leads/valuesDefaultInputsLeads/ValueDefaultInputLeads";
import { useContextReportsProduction } from "../ReportProduction/ReportsProductionContextContext";
import {
  InputsDefaultReportsConfirmationContract,
  InputsDefaultReportsConfirmationProducer,
  valuesDefaultInputsReportsProduction,
  valuesDefaultInputsReportsOperator,
} from "../ReportProduction/ValuesDefaultInputsReportProduction/ValuesDefaultInputsReportProduction";
import { UseAutoCompletedAll } from "../Utils/UseAutoCompletedAll";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useResetInputs = (isDisabledActive?: boolean) => {
  const {
    setListReportConfirmationContract,
    setValuesInputsProductionOperator,
    setValuesInputsProductionContract,
    setListReportProductionContract,
    setListReportProductionProducer,
    setValueAutoCompAdministradoras,
    setInputsConfirmationProducer,
    setInputsConfirmationContract,
    setValueAutoCompModalidades,
    setValueAutoCompOperadoras,
  } = useContextReportsProduction();

  const {
    setValueAutoCompAdministradora,
    setValueAutoCompModalidade,
    setValueAutoCompOperadora,
  } = UseAutoCompletedAll();

  const {
    setValuesInputsCommissions,
    setValuesInputsAdministradora,
    setValuesInputsModalidade,
    setValuesInputsOperadora,
    setValuesInputsEmpresas,
  } = useContextComissoes();

  const { setFilterMetricsFilterDashboard } = useContextDashboardContext();
  const { setNameSearch, setId } = useConfigPageContext();
  const { setValuesInputsLeads } = useContextLeads();

  const {
    setValuesReceivedInstallment,
    setValuesInputsContrato,
    setValuesFilterContrato,
    setUploadFiles,
  } = useContextContrato();

  const resetInputs = () => {
    setNameSearch("");
    setId("");

    setUploadFiles([]);

    setInputsConfirmationProducer(InputsDefaultReportsConfirmationProducer);
    setInputsConfirmationContract(InputsDefaultReportsConfirmationContract);
    setValuesInputsProductionContract(valuesDefaultInputsReportsProduction);
    setValuesInputsProductionOperator(valuesDefaultInputsReportsOperator);
    setFilterMetricsFilterDashboard(valueDefaultFilterMetricsDashboard);
    setValuesReceivedInstallment(valueDefaultInputReceivedInstallments);
    setValuesInputsCommissions(valuesDefaultInputsCommissions);
    setValuesFilterContrato(valuesDefaultFilterContrato);
    setValuesInputsContrato(valueDefaultInputContratos);
    setValuesInputsLeads(valuesDefaultInputLeads);

    setValuesInputsEmpresas({ name: "", isActive: true });
    setValuesInputsOperadora({ name: "", isActive: true });
    setValuesInputsModalidade({ name: "", isActive: true });
    setValuesInputsAdministradora({ name: "", isActive: true });

    setListReportConfirmationContract(undefined);
    setListReportProductionContract(undefined);
    setListReportProductionProducer(undefined);
    setValueAutoCompOperadoras([]);
    setValueAutoCompModalidades([]);
    setValueAutoCompAdministradoras([]);

    setValueAutoCompOperadora({ label: "", id: "" });
    setValueAutoCompModalidade({ label: "", id: "" });
    setValueAutoCompAdministradora({ label: "", id: "" });
  };

  return { resetInputs };
};
