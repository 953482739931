import { ChangeEvent } from "react";
import { Grid, MenuItem } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ICreateContrato } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { AppTextField } from "src/Pages/components";

import { HttpGetOperatorsAll } from "src/shared/Hooks/Https/HttpGetOperatorsAll";
import { HttpGetModalitiesAll } from "src/shared/Hooks/Https/HttpGetModalitiesAll";
import { HttpGetAdministradorasAll } from "src/shared/Hooks/Https/HttpGetAdministratorsAll";
import { HttpGetCompaniesAll } from "src/shared/Hooks/Https/HttpGetCompaniesAll";

interface IFormContrato {
  inputRef: React.RefObject<HTMLInputElement>;
}

export const FormContrato = ({ inputRef }: IFormContrato) => {
  const { valuesInputsContrato, setValuesInputsContrato } =
    useContextContrato();

  const { data: resultListOperators } = HttpGetOperatorsAll();
  const { data: resultListCompanies } = HttpGetCompaniesAll();
  const { data: resultListModalities } = HttpGetModalitiesAll();
  const { data: resultListAdministrators } = HttpGetAdministradorasAll();

  const handleChange =
    (prop: keyof ICreateContrato) => (event: ChangeEvent<HTMLInputElement>) => {
      setValuesInputsContrato((eventPrev) => ({
        ...eventPrev,
        [prop]: event.target.value,
      }));
    };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={5}>
        <AppTextField
          label="Proposta *"
          fullWidth
          placeholder="Código da proposta"
          inputRef={inputRef}
          value={valuesInputsContrato.proposalCode}
          onChange={handleChange("proposalCode")}
        />
      </Grid>
      <Grid item xs={6} md={3.5}>
        <AppTextField
          label="Data assinatura *"
          fullWidth
          type="date"
          value={valuesInputsContrato.subscriptionDate}
          onChange={handleChange("subscriptionDate")}
        />
      </Grid>
      <Grid item xs={6} md={3.5}>
        <AppTextField
          select
          id="tipo_contrato"
          label="Física | Jurídica"
          fullWidth
          value={valuesInputsContrato?.contractType}
          onChange={handleChange("contractType")}
        >
          <MenuItem value="PF">Física</MenuItem>
          <MenuItem value="PJ">Jurídica</MenuItem>
        </AppTextField>
      </Grid>

      <Grid item xs={6} md={2.5}>
        <AppTextField
          fullWidth
          select
          label="Operadora *"
          value={valuesInputsContrato.operatorCode}
          onChange={handleChange("operatorCode")}
        >
          {resultListOperators.map((option) => (
            <MenuItem key={option.code} value={String(option.code)}>
              {option.name}
            </MenuItem>
          ))}
        </AppTextField>
      </Grid>
      <Grid item xs={6} md={2.5}>
        <AppTextField
          fullWidth
          select
          label="Modalidade (produto) *"
          value={valuesInputsContrato.modalityCode}
          onChange={handleChange("modalityCode")}
        >
          {resultListModalities.map((option) => (
            <MenuItem key={option.code} value={String(option.code)}>
              {option.name}
            </MenuItem>
          ))}
        </AppTextField>
      </Grid>
      <Grid item xs={6} md={2.5}>
        <AppTextField
          fullWidth
          select
          label="Administradora"
          value={valuesInputsContrato.administratorCode}
          onChange={handleChange("administratorCode")}
        >
          {resultListAdministrators.map((option) => (
            <MenuItem key={option.code} value={String(option.code)}>
              {option.name}
            </MenuItem>
          ))}
        </AppTextField>
      </Grid>
      <Grid item xs={6} md={2.5}>
        <AppTextField
          fullWidth
          select
          label="Empresas"
          value={valuesInputsContrato.companyCode}
          onChange={handleChange("companyCode")}
        >
          {resultListCompanies.map((option) => (
            <MenuItem key={option.code} value={String(option.code)}>
              {option.name}
            </MenuItem>
          ))}
        </AppTextField>
      </Grid>
    </Grid>
  );
};
