import { Children, useEffect, useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import AddIcon from "@mui/icons-material/Add";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { ModalDeleteCore, TableCore, TdCore } from "src/Pages/components";
import { DeleteForeverIcon } from "src/Pages/components/Icons/Icons";

import { UseDelete } from "src/Pages/components/ModalDeleteCore/Hooks/UseDelete";
import { TableRowCore } from "src/Pages/components/table/@Core/TableRowCore/TableRowCore";
import { ActionPopoverTable } from "src/Pages/components/table/ActionPopover/ActionPopover";

import { useHttpTableContractsFiles } from "./Hooks/useHttpTableContractsFiles";
import { THeadContractsFiles } from "./THead";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { Box, IconButton, Typography } from "@mui/material";
import { ModalContractsFiles } from "../Form/indexForm";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import { handleFormatSize } from "src/shared/Utils/getFileSize";
import { IconImagePreview } from "src/Pages/components/CardPreviewFile/CardPreviewFile";

interface ITableContractsFiles {
  idContract?: string;
}

export const TableContractsFiles = ({
  idContract: id,
}: ITableContractsFiles) => {
  const { listContractsFiles } = useContextContrato();

  const { attTable } = useConfigPageContext();
  const { handleGet } = useHttpTableContractsFiles({
    contractId: id,
  });

  const [openModalContractsFiles, setOpenModalContractsFiles] = useState(false);
  const [idFileDelete, setIdFileDelete] = useState("");

  useEffect(() => {
    handleGet();
  }, [attTable]);

  if (!id) return null;

  const {
    handleDelete,
    open: openModalDelete,
    loading: loadingDelete,
    setId: setIdModalDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({
    url: `/contracts-files`,
    params: `idFile=${idFileDelete}`,
  });

  return (
    <TableCore
      isNotPagination
      heightTable="500px"
      Modals={
        <>
          <ModalContractsFiles
            openModal={openModalContractsFiles}
            setOpenModal={setOpenModalContractsFiles}
            id={id}
          />
          <ModalDeleteCore
            loading={loadingDelete}
            modalOpen={openModalDelete}
            no="Não, Cancelar"
            titlePrimary="Você deseja excluir da lista ?"
            yes="Sim, Deletar !"
            onClickFalse={() => setOpenModalDelete(false)}
            onClickTrue={() => handleDelete()}
          />
        </>
      }
      THead={<THeadContractsFiles />}
      qtdList={listContractsFiles.length}
      cardAdd={{
        title: "Arquivos",
        buttons: [
          <ButtonCore
            size="small"
            startIcon={<AddIcon />}
            title="Upload"
            onClick={() => setOpenModalContractsFiles(true)}
          />,
        ],
      }}
    >
      {Children.toArray(
        listContractsFiles.map((item) => {
          return (
            <TableRowCore id={String(item.id)}>
              <TdCore
                values={
                  <ActionPopoverTable
                    optionsList={[
                      {
                        icon: <RemoveRedEyeIcon fontSize="small" />,
                        title: "Visualizar",
                        onClick: () => {
                          window.open(item.url, "_blank");
                        },
                      },
                      {
                        icon: <DeleteForeverIcon fontSize="small" />,
                        title: "Excluir",
                        background: "error",
                        onClick: () => {
                          setIdModalDelete(item.idContract);
                          setOpenModalDelete(true);
                          setIdFileDelete(item.id);
                        },
                      },
                    ]}
                  />
                }
              />
              <TdCore
                values={
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    onClick={() => window.open(item.url, "_blank")}
                  >
                    <IconImagePreview type={item.mimeType} url={item.url} />
                    <Typography>{item.originalName}</Typography>
                  </Box>
                }
              />
              <TdCore values={handleFormatSize(item.size)} />
              <TdCore values={FormatDateBR(item.createdAt)} />

              <TdCore
                textAlign="center"
                onClick={() => window.open(item.url, "_blank")}
                values={
                  <IconButton>
                    <RemoveRedEyeIcon
                      sx={{ color: (theme) => theme.palette.primary.main }}
                    />
                  </IconButton>
                }
              />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
